import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';

import SEO from '../components/seo';
import Subheader from '../components/subheader';
import { Layout } from '../containers/layout';
import { Modal } from '../containers/modal';
import { PraxisTemplate } from '../pageTemplates/praxis';
import { GraphQLData } from '../types';
import { useStore } from '../zustand';

interface PraxisProps {
  data: GraphQLData;
  location: any;
}

const Team = ({ data, location }: PraxisProps) => {
  const therapist = data?.strapi?.therapist[0] || {};
  const { subheader, meta } = data.strapi?.praxi || {};
  const { title = 'Praxis', description } = meta ?? {};

  // change modal
  const [setModalLevel1Visible, setModalLevel1Data] = useStore(
    (state) => [state.setModalLevel1Visible, state.setModalLevel1Data],
    shallow
  );
  const changeModal = ({ id, ...rest }) => {
    setModalLevel1Data({ name: 'team', id, ...rest });
    setModalLevel1Visible(true);
  };

  useEffect(() => {
    changeModal(therapist);
  }, []);

  return (
    <Layout>
      <SEO description={description} title={title} />
      <Subheader intro={subheader} />
      <PraxisTemplate data={data} location={location} />
      <Modal team />
      <Modal services level="2" />
    </Layout>
  );
};

export const query = graphql`
  query TeamTemplate($slug: String!) {
    strapi {
      therapist: therapists(where: { slug: $slug }) {
        id
      }
      praxi {
        meta {
          title
          description
        }
        whyTitle
        therapistTitle
        subheader
        therapistDescription
        warumCarousel {
          description
          title
          image {
            caption
            url
            file {
              ...Image
            }
          }
        }
      }
      therapists {
        id
        title
        forename
        surname
        image {
          url
          file {
            ...Image
          }
        }
      }
    }
  }
`;

export default Team;
